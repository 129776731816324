.textNavigation {
    margin: auto;
    position: relative;
}
.textNavigation a{
    font-size: 15px;
    color: black;
    text-transform: uppercase;
    padding: 0 16px;
    margin: 0 20px;
 }
.navbar-brand {
    width:10%;
}
.navbar-brand img{
    width: 100%;
}
.textNavigation a:hover .dropDown{
    top: 20px;
    visibility: visible;
    transition: all .2s ease-in;
}
.dropDown{
    position: absolute;
    top: 150px;
    z-index: 9;
    background-color: rgb(248,249,250);
    padding: 15px;
    visibility: hidden;
    width: 200px;
}
.dropDown a{ 
    padding: 10px 0;
    text-decoration: none;
    margin: 0;
    display: block;
    
}
.dropDown.navbar-expand-lg .navbar-nav .nav-link{
    padding: 0;
}
