.productsCard figure > a {
    display: flex;
    justify-content: center;
}
.text-dark a{
    color: black;
    font-size: 22px;
    font-weight: 700;
}

.productsCard {
    -webkit-box-shadow: -1px 5px 14px 0px rgb(66 68 90 / 19%);
    -moz-box-shadow: -1px 5px 14px 0px rgb(66 68 90 / 19%);
    box-shadow: -1px 5px 14px 0px rgb(66 68 90 / 19%);
    border-radius: 5px;
    overflow: hidden;
    
}

/* .productsCard a img {
    transition: all 0.9s ease-in-out;
} */
.productsCard a img:hover {
    transform: scale(1.2);
    transition: all 0.9s ease-in-out;
}
.price span{
    font-size: 14px;
    font-weight: 700;
    
}
.addToCart{
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0 8px;
}
.productsCard .sci  {
	list-style: none;
	margin: 0 10px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
    position: absolute;
}
.productsCard.sci {
	position: absolute;
	/* bottom: 50px; */
	display: flex;
}
/* .productsCard :hover.productTitle{
    transform: translateY(-80px);
} */
.productsCard:hover .sci {
	transform: translateY(0px);
	opacity: 1;
    position: relative;
}