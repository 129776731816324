.bottomShaddow {
    /* -webkit-box-shadow: -1px 5px 15px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: -1px 5px 15px 0px rgba(66, 68, 90, 1);
    box-shadow: -1px 5px 15px 0px rgba(66, 68, 90, 1); */
    border-top: solid 1px rgba(0, 0, 0, 0.084);
    border-bottom: solid 1px rgba(0, 0, 0, 0.137);
}

.btnLink {
    -webkit-box-shadow: -1px 5px 15px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: -1px 5px 15px 0px rgba(66, 68, 90, 1);
    box-shadow: -1px 5px 15px 0px rgba(66, 68, 90, 1);
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    color: #000;
}