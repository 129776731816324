.productsCard figure > a {
    display: flex;
    justify-content: center;
}
.text-dark a{
    color: black;
    font-size: 22px;
    font-weight: 700;
}
/* .text-hove-pos{
    position: absolute;
}
.productsCard1:hover.text-hove-pos{
    top: 120px;
}  */

.productsCard1:hover {
    -webkit-box-shadow: -1px 5px 14px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: -1px 5px 14px 0px rgba(66, 68, 90, 1);
    box-shadow: -1px 5px 14px 0px rgba(66, 68, 90, 1);
    border-radius: 5px;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    
}
.productsCard1{
    border: solid 1px rgba(193, 148, 148, 0.282);transition: 0.5s ease-in-out;
}

/* .productsCard a img {
    transition: all 0.9s ease-in-out;
} */
.productsCard1 a img:hover {
    transform: scale(1.2);
    transition: all 0.9s ease-in-out;
}
#price span{
    font-weight: 700;
    width: fit-content;
    
}
.addToCart{
    font-size: 22px;
    display: flex;
    align-items: center;
    margin: 0 8px;
}
.productsCard1 .sci  {
	list-style: none;
	margin: 0 10px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
    position: absolute;
}
.productsCard1.sci {
	position: absolute;
	/* bottom: 50px; */
	display: flex;
}
/* .productsCard1 :hover.productTitle{
    transform: translateY(-80px);
} */
.productsCard1:hover .sci {
	transform: translateY(0px);
	opacity: 1;
    position: relative;
}