.bgColorSeries {
    background-color: rgb(45, 116, 184);
    padding: 20px 0;
}

.backgroundProBorder {
    box-shadow: -1px -1px 9px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -1px -1px 9px 4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -1px -1px 9px 4px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 25px;
}
    

  

.seriesContent {
    color: white;
    padding: 12px;
    box-sizing: border-box;
}
.seriesHeader h4 {
    text-align: center;
}
/* wipe up */
.scenes {
    position: relative;
    aspect-ratio: 2.4 / 2.5;
}
.scene-1,
.scene-2 {
    position: absolute;
    inset: 0;
    background-size: cover;
}
.scene-2 {
    -webkit-mask-image: linear-gradient(to left,
            transparent 47.5%,
            #fff 52.5%);
    -webkit-mask-size: 210%;
    -webkit-mask-position: right;
}
.scenes:is(:hover, :focus) .scene-2 {
    -webkit-mask-position: left;
    transition: -webkit-mask-position 1s linear;
}
.scene-title {
    position: absolute;
    bottom: 0;
}
.scene-1 .scene-title {
    top: 0;
    right: 0;
    color: #000;
    text-align: right;
}
.scene-2 .scene-title {
    bottom: 0;
    right: 0;
    color: #fff;
    text-align: center;
}
/* everything else */
*,
*::before,
*::after {
    box-sizing: border-box;
}
.wrapper {
    display: grid;
    gap: 30px;
    width: min(1000px, 100%);
}
.intro {
    text-align: center;
}
.heading {
    margin: 0;
    font-size: 2.5em;
}
.heading::before,
.heading::after {
    content: ' — ';
}
.nudge {
    margin: 0;
    color: #888;
    font-size: 1.25em;
}
.visualizer {
    display: flex;
    justify-content: center;
}
.visualizer-frame {
    position: relative;
    width: min(150px, 25vw);
    aspect-ratio: 2.4 / 1;
    outline: 2px solid #ccc;
}
.visualizer-mask {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    width: 210%;
    background-color: #333;
    background-image:
        linear-gradient(to left, transparent 47.5%, #fff 52.5%),
        linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%),
        linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%);
    background-size:
        auto auto,
        16px 16px,
        16px 16px;
    background-position:
        0 0,
        0 0,
        8px 8px;
    box-shadow: inset 0 0 0 2px #111;
}
.scenes:is(:hover, :focus)+.visualizer .visualizer-mask {
    transform: translateX(calc((100% - 210%) / 2.1));
    transition: transform 2s linear;
}