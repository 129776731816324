.searchBox input{
    border: none;
    outline: none;
    box-shadow: 0px -3px 0px 4px rgba(0,0,0,0.06);
    -webkit-box-shadow: 0px -3px 0px 4px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px -3px 0px 4px rgba(0,0,0,0.06);
}
.selectCountry select{
    outline: none;
}
.countItemTopCart {
    background-color: #9a9a9a;
    padding: 0 10px;
    margin: 0 10px;
    font-weight: 600;
}
.countItemTopCart span{
    font-size: 12px;
    font-weight: 600;
    color: #fff;
}
.loginbtn{
    font-size: 12px;
    color: #fff;

}
.fit-to-contentBtn{
    width: 100%;
    
}
@media screen and  (max-width: 480px) {
    .emailReq >.emailReqCont {display: none;}
    
}

