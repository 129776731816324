.newsBg{
    background-image: url('../../../assets/img/networkBg/emailInqBg.jpg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
}
.newLetter{
    width: 600px;
    margin: auto;
}
.enterEmail span input{
    outline: none;
    border: none;
}