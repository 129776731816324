.counter {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border:  solid 1px #80808078; */
    border-radius: 4px;
    height: 22px;
}
.counter input {
    width: 50px;
    border: 0;
    font-size: 22px;
    text-align: center;
    font-weight: bolder;
    display: inline-block;
    appearance: none;
    outline: 0;
    background-color: #80808005;
}
.counter span {
    display: block;
    font-size: 20px;
    padding: 0 5px;
    cursor: pointer;
    color: #0052cc;
    user-select: none;
}